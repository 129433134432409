import {SmartLink} from '@mfb/lego';
import * as React from 'react';

import {MetadataContentModel} from '../contentful/metadata/MetadataContentModel';
import PageTemplate from '../templates/PageTemplate';

const metadata: MetadataContentModel = {
  title: 'An error occurred',
  description: {
    description: 'An unexpected error occurred.',
  },
  type: 'article',
  canonicalUrlLink: {
    relativePath: '/error',
  },
  sharingLinkImage: {
    id: '',
    __typename: '',
    alternateText: '',
    small: {
      id: '',
      file: {
        url: '',
      },
    },
    large: {
      id: '',
      file: {
        url: '',
      },
    },
  },
  keywords: [],
  allowIndexing: false,
};

const ErrorPage: React.FunctionComponent = () => (
  <PageTemplate
    metadata={metadata}
    render={() => (
      <div className="container py-5">
        <h1 className="pb-2">An error occurred</h1>
        <p>
          Sorry! An unexpected error has occurred and we are looking into it.
          Please try again in a few minutes.
        </p>
        <p>
          If that still {"doesn't"} work, please contact us by clicking the
          button below.
        </p>

        <SmartLink href="/contact" className="btn btn-primary">
          Contact Us
        </SmartLink>
      </div>
    )}
  />
);

export default ErrorPage;
